@charset "utf-8";

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, form, fieldset, legend, input, button, p, blockquote, th, td, code {
  margin: 0;
  padding: 0;
  font-size: 1em; 
}

table {
  border-collapse: collapse;
  border-spacing: 0; 
}

fieldset, img {
  border: 0; 
}

input[type="reset"], 
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; 
}

input[type="search"] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield; 
}

input[type="search"]::-webkit-search-decoration, 
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

img {
  max-width: 100%;
  width: auto;
  height: auto;
  vertical-align: middle;
  display: inline;
  -ms-interpolation-mode: bicubic; 
}

address, caption, cite, code, dfn, em, strong, th, var, small {
  font-style: normal;
  font-weight: inherit; 
}

address {
  display: block; 
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
  display: block; 
}

object, embed, video, iframe, audio, canvas {
  max-width: 100%;
  border: 0; 
}

audio, canvas {
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle; 
}

video {
  display: inline-block; 
}

/* ol, ul {
  list-style: none;
} */

caption, th {
  text-align: left; 
}

q::after {
  content: close-quote; 
}

q::before {
  content: open-quote; 
}

b, strong {
  font-weight: bold; 
}

i, em {
  font-style: italic; 
}

u {
  text-decoration: underline; 
}

sub, sup {
  vertical-align: baseline; 
}

small {
  font-size: smaller; 
}

button {
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  cursor: pointer; 
}

button, input {
  font-weight: normal; 
}

label, button, select, textarea {
  font-weight: normal;
  max-width: 100%; 
}

button, input, select, textarea {
  vertical-align: middle; 
}

button::-moz-focus-inner, 
input::-moz-focus-inner {
  padding: 0;
  border: 0; 
}

button, html input[type="button"] {
  -webkit-appearance: button;
  cursor: pointer; 
}

pre {
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
  white-space: pre-wrap; 
}

pre code {
  color: inherit;
  background-color: transparent;
  border: 0; 
}

abbr[title], 
abbr[data-original-title], 
acronym[title], 
acronym[data-original-title], 
dfn[title],
dfn[data-original-title] {
  cursor: help; 
}

.cl, br {
  clear: both;
  font-size: 1px;
  height: 1px;
  line-height: 1px; 
}