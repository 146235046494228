@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'), url(../fonts/opensans/opensans-light.woff2) format('woff2'), url(../fonts/opensans/opensans-light.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(../fonts/opensans/opensans.woff2) format('woff2'), url(../fonts/opensans/opensans.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'), url(../fonts/opensans/opensans-semi-bold.woff2) format('woff2'), url(../fonts/opensans/opensans-semi-bold.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'), url(../fonts/opensans/opensans-bold.woff2) format('woff2'), url(../fonts/opensans/opensans-bold.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local('Open Sans Extrabold'), local('OpenSans-Extrabold'), url(../fonts/opensans/opensans-extra-bold.woff2) format('woff2'), url(../fonts/opensans/opensans-extra-bold.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: local('Open Sans Light Italic'), local('OpenSansLight-Italic'), url(../fonts/opensans/opensans-light-italic.woff2) format('woff2'), url(../fonts/opensans/opensans-light-italic.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Open Sans Italic'), local('OpenSans-Italic'), url(../fonts/opensans/opensans-italic.woff2) format('woff2'), url(../fonts/opensans/opensans-italic.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: local('Open Sans Semibold Italic'), local('OpenSans-SemiboldItalic'), url(../fonts/opensans/opensans-semi-bold-italic.woff2) format('woff2'), url(../fonts/opensans/opensans-semi-bold-italic.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'), url(../fonts/opensans/opensans-bold-italic.woff2) format('woff2'), url(../fonts/opensans/opensans-bold-italic.woff) format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: local('Open Sans Extrabold Italic'), local('OpenSans-ExtraboldItalic'), url(../fonts/opensans/opensans-extra-bold-italic.woff2) format('woff2'), url(../fonts/opensans/opensans-extra-bold-italic.woff) format('woff');
}